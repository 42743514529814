import { FC } from "react";
import styles from "./LoadingScreen.module.css";
import { Skeleton } from "../Skeleton";

interface LoadingScreenProps {}

export const LoadingScreen: FC<LoadingScreenProps> = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <Skeleton width={"100%"} height={"130px"} borderRadius={16} />
        <Skeleton width={"100%"} height={"91px"} borderRadius={16} />
      </div>
      <Skeleton width={"100%"} height={"47px"} />
      <div className={styles.wrapper}>
        <Skeleton width={"100%"} height={"182px"} borderRadius={16} />
      </div>
    </>
  );
};
