import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import styles from "./Input.module.css";
import { ReactComponent as ArrowRight } from "../../../shared/assets/arrow-right.svg";

interface InputProps {
  focus: boolean;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
  onFocus: () => void;
}

export const Input: FC<InputProps> = (props) => {
  const { focus, value, error, onChange, onFocus } = props;

  const ref = useRef<HTMLInputElement | null>(null);
  const [isFocused, setIsFocused] = useState(true);

  useEffect(() => {
    if (focus) {
      ref.current?.focus();
    }
  }, [focus]);

  useEffect(() => {
    ref.current?.addEventListener("focus", onFocus);

    return () => {
      ref.current?.removeEventListener("focus", onFocus);
    };
  }, [onFocus]);

  useEffect(() => {
    setIsFocused(document.activeElement === ref.current);
  }, [document.activeElement]);

  const classes = [styles.input];
  const arrowClasses = [styles.arrow];

  if (error) {
    classes.push(styles.error);
  }

  if (isFocused) {
    arrowClasses.push(styles.arrowFocused);
  }

  return (
    <div className={styles.wrapper}>
      <input ref={ref} className={classes.join(" ")} value={value} onChange={onChange} />
      <ArrowRight className={arrowClasses.join(" ")} />
    </div>
  );
};
