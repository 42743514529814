import { FC } from "react";
import styles from "./NoAuthDataScreen.module.css";
import { MainButton } from "@vkruglikov/react-telegram-web-app";

interface NoAuthDataScreenProps {}

export const NoAuthDataScreen: FC<NoAuthDataScreenProps> = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.qr}>
        <img src={"/qr.png"} alt={"QR code"} />
      </div>
      <a className={styles.button} href={"https://t.me/schedule_arbitr_bot"}>
        Перейти в Telegram
      </a>
    </div>
  );
};
