import { FC, useEffect, useState } from "react";
import styles from "./AppBar.module.css";

interface AppBarProps {}

export const AppBar: FC<AppBarProps> = () => {
  const [theme, setTheme] = useState<"dark" | "light">(window.Telegram.WebApp.colorScheme);

  useEffect(() => {
    window.Telegram.WebApp.onEvent("themeChanged", () => {
      setTheme(window.Telegram.WebApp.colorScheme);
    });
  }, []);

  return (
    <div className={styles.appBar}>
      <img className={styles.logo} src={theme === "dark" ? "/logo_hw.svg" : "/logo_hb.svg"} alt={"logo"} />
    </div>
  );
};
