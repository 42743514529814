import { FC, useEffect, useRef, useState } from "react";
import styles from "./Tabs.module.css";

interface TabsProps {
  activeTab: number;
  onTabClicked: (tabId: number) => void;
}

export const Tabs: FC<TabsProps> = (props) => {
  const { activeTab, onTabClicked } = props;

  const sliderRef = useRef<HTMLSpanElement | null>(null);
  const tabsRef = useRef<HTMLDivElement | null>(null);
  const [sliderWidth, setSliderWidth] = useState(0);
  const [sliderTranslate, setSliderTranslate] = useState(0);

  useEffect(() => {
    const tabNode = tabsRef.current?.children[activeTab];
    const tabWidth = tabNode?.getBoundingClientRect().width;
    if (!tabWidth) return;
    setSliderWidth(tabWidth);
    setSliderTranslate(activeTab * sliderWidth);
  }, [activeTab]);

  const tabs = [
    { index: 0, title: "Текущее дело" },
    { index: 1, title: "Следующие дела" },
  ];

  const handleTabClicked = (id: number) => () => {
    onTabClicked(id);
  };

  return (
    <div className={styles.wrapper}>
      <div ref={tabsRef} className={styles.tabsWrapper}>
        {tabs.map((tab) => {
          const classes = [styles.tab];
          if (activeTab === tab.index) {
            classes.push(styles.active);
          }
          return (
            <div key={tab.index} className={classes.join(" ")} onClick={handleTabClicked(tab.index)}>
              <span className={styles.tabTitle}>
                {tab.title}
              </span>

            </div>
          );
        })}
        <span
          ref={sliderRef}
          style={{ width: sliderWidth, transform: `translateX(${sliderTranslate}px)` }}
          className={styles.slider}
        ></span>
      </div>
    </div>
  );
};
