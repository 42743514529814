import { FC, useEffect, useState } from "react";
import { MainButton, useHapticFeedback, useThemeParams } from "@vkruglikov/react-telegram-web-app";
import { AutoComplete } from "../AutoComplete";
import { Drawer } from "../Drawer";

export interface SearchItem {
  title: string;
  value: string;
  type: string;
}

interface SearchProps {
  isActive: boolean;
  title: string;
  items: SearchItem[];
  onChange: (item: SearchItem) => void;
  onClose: () => void;
}

export const Search: FC<SearchProps> = (props) => {
  const { isActive, title, items, onChange, onClose } = props;
  const [selectedItem, setSelectedItem] = useState<SearchItem | null>(null);
  const [error, setError] = useState(false);
  const [_, themeParams] = useThemeParams();
  const [impactOccurred, notification, selection] = useHapticFeedback();

  useEffect(() => {
    if (!isActive) {
      window.document.body.style.overflow = "auto";
      setSelectedItem(null);
    } else {
      window.scrollTo(0, 0);
      window.document.body.style.overflow = "hidden";
    }
  }, [isActive]);

  const handleChange = (item: SearchItem | null) => {
    setSelectedItem(item);
    selection();
  };

  const handleSave = () => {
    if (!selectedItem) {
      setError(true);
      return;
    }

    impactOccurred("heavy");

    onChange(selectedItem);
    onClose();
  };

  return (
    <Drawer title={title} onClose={onClose} isActive={isActive}>
      <AutoComplete isActive={isActive} error={error} items={items} onChange={handleChange} />
      {selectedItem && <MainButton text={"Сохранить"} onClick={handleSave} />}
    </Drawer>
  );
};
