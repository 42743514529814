import { FC } from "react";
import styles from "./Input.module.css";
import { ReactComponent as EditIcon } from "../../shared/assets/edit.svg";

interface InputProps {
  label: string;
  value?: string;
  placeholder?: string;
  onChange: () => void;
}

export const Input: FC<InputProps> = (props) => {
  const { label, value, placeholder, onChange } = props;
  const iconColor = "var(--tg-theme-hint-color)";

  return (
    <div className={styles.wrapper} onClick={onChange}>
      <div className={styles.inputWrapper}>
        <div className={styles.valueWrapper}>
          <span className={styles.label}>{label}</span>
          {!value && placeholder && <p className={styles.placeholder}>{placeholder}</p>}
          {value && <p className={styles.value}>{value}</p>}
        </div>

        <div className={styles.icon}>
          <EditIcon color={iconColor} />
        </div>
      </div>
    </div>
  );
};
