import { FC, useEffect } from "react";
import styles from "./ObserverList.module.css";
import { useData } from "../../providers/DataProvider";
import { ReactComponent as CancelIcon } from "../../shared/assets/cancel.svg";
import { Drawer } from "../Drawer";
import { Section } from "../Section";

interface ObserverListProps {
  isActive: boolean;
  onClose: () => void;
}

export const ObserverList: FC<ObserverListProps> = (props) => {
  const { isActive, onClose } = props;
  const { observerList, isObserversLoading, fetchObservers, cancelObserve } = useData();

  useEffect(() => {
    if (isActive) {
      fetchObservers();
    }
  }, [isActive]);

  const handleCancelObserve = (id: number) => () => {
    cancelObserve(id);
  };

  return (
    <Drawer isActive={isActive} title={"Наблюдение за залами"} onClose={onClose}>
      {!isObserversLoading && !observerList.length && (
        <span className={styles.placeholder}>Нет активных подписок</span>
      )}
      {!isObserversLoading && (
        <>
          <Section>
            <ul className={styles.list}>
              {observerList.map((observer) => (
                <li key={observer.id} className={styles.item}>
                  <div className={styles.value}>
                    <p>{observer.hall}</p>
                    <span className={styles.courtLabel}>{observer.court}</span>
                  </div>

                  <CancelIcon
                    className={styles.icon}
                    width={25}
                    height={25}
                    color={"var(--tg-theme-destructive-text-color)"}
                    onClick={handleCancelObserve(observer.id)}
                  />
                </li>
              ))}
            </ul>
          </Section>
          <p className={styles.hint}>Одновременно можно отслеживать до трех залов</p>
        </>
      )}
    </Drawer>
  );
};
