import { FC, ReactNode } from "react";
import styles from './Section.module.css'

interface SectionProps {
  children: ReactNode;
}

export const Section: FC<SectionProps> = (props) => {
  return (
    <div className={styles.section}>
      {props.children}
    </div>
  )
};