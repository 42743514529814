import { FC, ReactNode } from "react";
import styles from './SafeArea.module.css'

interface SafeAreaProps {
  children: ReactNode;
}

export const SafeArea: FC<SafeAreaProps> = (props) => {
  return (
    <div className={styles.content}>
      {props.children}
    </div>
  )
};