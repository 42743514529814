import { FC, HTMLProps, MouseEvent } from "react";
import styles from "./Link.module.css";

interface LinkProps extends HTMLProps<HTMLAnchorElement> {}

export const Link: FC<LinkProps> = (props) => {

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.Telegram.WebApp.openLink(props.href)
  }

  return <a className={styles.link} {...props} onClick={handleClick} />
};
