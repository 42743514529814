import { FC, ReactNode, useEffect, useState } from "react";
import { useThemeParams } from "@vkruglikov/react-telegram-web-app";
import { SafeArea } from "../SafeArea";
import styles from "./Drawer.module.css";

interface DrawerProps {
  children: ReactNode;
  isActive: boolean;
  title: string;
  onClose: () => void;
}

export const Drawer: FC<DrawerProps> = (props) => {
  const { isActive, title, onClose, children } = props;
  const [_, themeParams] = useThemeParams();
  const [isMounted, setIsMounted] = useState(false);
  const [additionalStyles, setAdditionalStyles] = useState([styles.left]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isActive) {
      window.document.body.style.overflow = "auto";
    } else {
      window.scrollTo(0, 0);
      window.document.body.style.overflow = "hidden";
    }
  }, [isActive]);

  useEffect(() => {
    if (!isMounted) return;
    if (isActive) {
      setAdditionalStyles([styles.openLeft]);
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(handleClose);
    } else {
      setAdditionalStyles([styles.closeLeft]);
      window.Telegram.WebApp.BackButton.hide();
      window.Telegram.WebApp.BackButton.offClick(handleClose);
    }
  }, [isActive]);

  const handleClose = () => {
    setAdditionalStyles([styles.closeLeft]);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  return (
    <div className={[styles.wrapper, ...additionalStyles].join(" ")}>
      <SafeArea>
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
        </div>
        {children}
      </SafeArea>
    </div>
  );
};
