import { FC } from "react";
import styles from "./Skeleton.module.css";

interface SkeletonProps {
  className?: string;
  width: string;
  height: string;
  borderRadius?: number;
}

export const Skeleton: FC<SkeletonProps> = (props) => {
  const { className, width, height, borderRadius } = props;

  const classes = [styles.skeleton];

  if (className) {
    classes.push(className);
  }

  return (
    <div
      className={classes.join(" ")}
      style={{ width, height, borderRadius: borderRadius ? `${borderRadius}px` : "none" }}
    />
  );
};
