import { FC } from "react";
import styles from "./CaseCard.module.css";
import { Case } from "../../shared/types";
import { Link } from "../Link";

interface CaseCardProps {
  data: Case;
}

export const CaseCard: FC<CaseCardProps> = (props) => {
  const {
    data: { caseName, time, judgeName, plaintiff, defendant, caseId },
  } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Link href={`https://kad.arbitr.ru/Card/${caseId}`} target={"_blank"}>
          {caseName}
        </Link>
        <span className={styles.value}>{time}</span>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <span className={styles.label}>Судья</span>
          <span className={styles.value}>{judgeName}</span>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <span className={styles.label}>Ответчик</span>
          <span className={styles.value}>{defendant}</span>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <span className={styles.label}>Истец</span>
          <span className={styles.value}>{plaintiff}</span>
        </div>
      </div>
    </div>
  );
};
