import { ChangeEvent, useEffect, useState } from "react";
import styles from "./AutoComplete.module.css";
import { Input } from "./Input";
import { useThemeParams } from "@vkruglikov/react-telegram-web-app";

interface AutoCompleteProps<T> {
  isActive: boolean;
  items: T[];
  error: boolean;
  onChange: (item: T | null) => void;
}

export const AutoComplete = <T extends { value: string; title: string }>(props: AutoCompleteProps<T>) => {
  const { isActive, items, error, onChange } = props;

  const [colorScheme] = useThemeParams();
  const [value, setValue] = useState("");
  const [isItemsVisible, setIsItemsVisible] = useState(true);
  const [completeItems, setCompleteItems] = useState(items);

  useEffect(() => {
    if (!isActive) {
      setValue("");
    }
  }, [isActive]);

  useEffect(() => {
    setCompleteItems(items);
    setIsItemsVisible(true);
  }, [items]);

  const handleInputValueChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setCompleteItems(items.filter((item) => item.title.toLowerCase().includes(e.target.value.toLowerCase())));
    onChange(null);
  };

  const handleItemChanged = (item: T) => () => {
    setValue(item.title);
    setIsItemsVisible(false);
    onChange(item);
  };

  const handleInputFocus = () => {
    setIsItemsVisible(true);
  };

  return (
    <div className={styles.wrapper}>
      <Input
        value={value}
        error={error}
        focus={isActive}
        onChange={handleInputValueChanged}
        onFocus={handleInputFocus}
      />
      {isItemsVisible && (
        <ul className={styles.items}>
          {completeItems.map((item, i) => (
            <li
              key={item.value}
              className={[styles.item, colorScheme === "dark" ? styles.dark : styles.light].join(" ")}
              onClick={handleItemChanged(item)}
            >
              {item.title}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
