import { FC, ReactNode } from "react";
import styles from "./Page.module.css";

interface PageProps {
  children: ReactNode;
}

export const Page: FC<PageProps> = (props) => {
  return <div className={styles.wrapper}>{props.children}</div>;
};
